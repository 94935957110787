<template>
  <b-container class="pt-2" fluid="md">

    <h3 class="mt-4">Clients</h3>

    <form ref="form">
      <b-form-row>
        <b-col>
          <b-form-group
            label="Rechercher un client"
            label-for="search"
            invalid-feedback="min 3 caratères"
            required                          
            :state="searchState"
          >
          <b-input-group-append>
            <b-form-input id="search" v-model="keyword" @keyup="searchClient()"></b-form-input>
          </b-input-group-append>
          </b-form-group>
        </b-col>
      </b-form-row>
    </form>


    <b-skeleton-wrapper :loading="loading">
      <template #loading>
        <b-card>
          <b-skeleton width="85%"></b-skeleton>
          <b-skeleton width="55%"></b-skeleton>
          <b-skeleton width="70%"></b-skeleton>
        </b-card>
      </template>

      <div class="py-2">
        <b-button @click="openModalClient()" variant="info"> Nouveau </b-button>
      </div>
      
        <!-- <b-card v-for="client in filtreClients" :key="client.id" header-tag="header" border-variant="info" class="mb-3">
          <template #header>
            
            <div class="d-flex flex-column">
              <div class="d-flex w-100 justify-content-between">
                <h6 v-if="client" class="mb-0 align-items-center">{{client.raison_sociale}}</h6>
              </div>
             </div> 
          </template>
        <b-card-body>

              <div class="d-flex w-100 justify-content-between">
                <h6 class="mb-1">{{ client.adresse_rdv}}</h6>
              </div>
               <p class="mb-1">{{ client.num_tel_1_rdv}}</p>
                <p class="mb-1">{{client.num_tel_2_rdv}}</p>
              
              <div class="d-flex w-100 justify-content-between">
                <small>Début: {{ client.date_calendar_debut}}</small>
               <b-button variant="info" :to="`/dossier/${client.id_organisation}`" size="sm">Commande</b-button>
   </div>

      </b-card-body> 
    </b-card>     -->



    <div>

    <h2 class="mt-4">Rendez-vous passés</h2>
    <b-card>
      <b-list-group v-if="pastAppointments.length > 0">
        <b-list-group-item v-for="client in pastAppointments" :key="client.date_calendar_debut" class="d-flex flex-row">
          <strong>{{ client.date_calendar_debut }}</strong>

          <div class="d-flex flex-column w-100">
            <div  v-for="app in client.appointments" :key="app.id">
              <div  class=" d-flex flex-row ml-3 mb-2 justify-content-between">
                  <h6>{{app.raison_sociale}}</h6>
                  <b-button v-if="app.da_id_dossier == null" variant="info" :to="`/dossier/${app.id_organisation}`" size="sm">Dossier</b-button>
                  <b-button v-else variant="info" :to="`/dossier/${app.id_organisation}/${app.da_id_dossier}`" size="sm">Commande</b-button>
              </div>
            </div>
          </div>
          
        </b-list-group-item>
      </b-list-group>
      <b-card-body v-else>
          Aucun rendez-vous à passé
        </b-card-body>
    </b-card>

    <h2 class="mt-4">Rendez-vous du jour</h2>
    <b-card>
      <b-list-group v-if="todayAppointments.length > 0">
        <b-list-group-item v-for="hour in hoursOfDay" :key="hour" class="d-flex justify-content-between align-items-center">
          <strong>{{ hour }}</strong>

          <div class="d-flex flex-column w-100">
            <div v-for="client in todayAppointments" :key="client.id">
              <div v-if="client && client.date_calendar_debut.split(' ')[1] == hour " class=" d-flex flex-row ml-3 justify-content-between align-items-center">
                  <h6>{{client.raison_sociale}}</h6>
                  <b-button v-if="client.da_id_dossier == null" variant="info" :to="`/dossier/${client.id_organisation}`" size="sm">Dossier</b-button>
                  <b-button v-else variant="info" :to="`/dossier/${client.id_organisation}/${client.da_id_dossier}`" size="sm">Commande</b-button>
                  
              </div>
            </div>
        </div>
        </b-list-group-item>
      </b-list-group>
      <b-card-body v-else>
        Aucun rendez-vous aujourd'hui
      </b-card-body>
    </b-card>

    <h2 class="mt-4">Rendez-vous à venir</h2>
    <b-card>
      <b-list-group v-if="futurAppointments.length > 0">
        <b-list-group-item v-for="client in futurAppointments" :key="client.date_calendar_debut" class="d-flex flex-row">
          <strong>{{ client.date_calendar_debut }}</strong>

          <div class="d-flex flex-column w-100">
            <div  v-for="app in client.appointments" :key="app.id">
              <div  class=" d-flex flex-row ml-3 mb-2 justify-content-between">
                  <h6>{{app.raison_sociale}}</h6>
                  <b-button v-if="app.da_id_dossier == null" variant="info" :to="`/dossier/${app.id_organisation}`" size="sm">Dossier</b-button>
                  <b-button v-else variant="info" :to="`/dossier/${app.id_organisation}/${app.da_id_dossier}`" size="sm">Commande</b-button>
              </div>
            </div>
          </div>
          
        </b-list-group-item>
      </b-list-group>
      <b-card-body v-else>
        Aucun rendez-vous à venir
      </b-card-body>
    </b-card>
    </div>

  </b-skeleton-wrapper>
   <!-- Modale -->
    <b-modal
        id="modal-client" ref="modal"
        title="Nouveau client"
        okTitle= 'Envoyer'
        cancelTitle= 'Annuler'
        header-bg-variant="info"
        header-text-variant="white"
        size="lg"
        @show="resetModalClient"
        @hidden="resetModalClient"
        @ok="handleClientOk"
      >
      
      <validation-observer ref="observer" v-slot="{ handleClientOk }">
        <!-- Tabs with card integration -->
          <b-form ref="formRDV" @submit.prevent="handleClientOk()" @reset="onReset">
            <b-form-row>
              <b-col md="12">
                <b-card title="Signataire" class="mb-3" >     
                    <b-form-row>
                        <b-col md="12">
                          <b-form-group label="" label-for="typeClient">
                            <b-form-radio-group button-variant="outline-info" 
                              id="typeClient"
                              name="typeClient"
                              v-model="typeClient"  
                              :options="typeClientList"
                              >
                            </b-form-radio-group>
                          </b-form-group>
                        </b-col>
                      </b-form-row>            
                      <b-form-row v-if="typeClient == 'Entreprise'">
                        <b-col md="6">
                          <TextInput id="raison_sociale" type="text" label="Raison sociale" :rules="{ required: true, min: 3 }" v-model="informations.raison_sociale"></TextInput>
                        </b-col>
                        <b-col md="6">
                          <TextInput id="siren" type="text" label="Code siret" :rules="{ required: true, min: 3 }" v-model="informations.siren"></TextInput>
                        </b-col>
                      </b-form-row>
                      <b-form-row >
                      <b-col md="6">
                        <TextInput id="nom" type="text" label="Nom" :rules="{ required: true, min: 3 }" v-model="informations.nom"></TextInput>
                      </b-col>
                      <b-col md="6">
                        <TextInput id="prenom" type="text" label="Prénom" :rules="{ required: true, min: 3 }" v-model="informations.prenom"></TextInput>
                      </b-col>
                    </b-form-row>
                    <b-form-row >
                      <b-col md="12">
                        <TextInput id="adresse" type="text" label="Adresse" :rules="{ required: true, min: 3 }" v-model="informations.adresse"></TextInput>             
                      </b-col>
                    </b-form-row>
                    <b-form-row>            
                      <b-col md="3">
                        <TextInput id="codePostal" type="number" label="Code postal" :rules="{ required: true, min: 5 , max:5}" v-model="informations.codePostal"></TextInput>             
                      </b-col>
                      <b-col md="9">
                        <TextInput id="ville" type="text" label="Ville" :rules="{ required: true, min: 3 }" v-model="informations.ville"></TextInput>             
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col md="6">
                        <TextInput id="email" type="email" label="Email" :rules="{ required: true, email: true}" v-model="informations.email"></TextInput>             
                      </b-col>
                      <b-col md="3">
                        <TextInput id="telephone" type="tel" label="Tél." :rules="{ required: false, min: 10, max:10 }" v-model="informations.telephone"></TextInput>             
                      </b-col>
                      <b-col md="3">
                        <TextInput id="mobile" type="tel" label="Mobile" :rules="{ required: true, min: 10 , max: 10}" v-model="informations.mobile"></TextInput>                 
                      </b-col>
                    </b-form-row>
                    <b-form-row>
                      <b-col md="12">
                        <b-form-checkbox v-model="memeAdresse" name="check-button" switch @click="memeAdresse = !memeAdresse">
                          Meme adresse
                        </b-form-checkbox>            
                      </b-col>                     
                    </b-form-row>
                </b-card>
              </b-col>             
            </b-form-row>
            <b-form-row v-if="!memeAdresse">
              <b-col md="12">
                <b-card title="Installation" class="mb-3">
                      <b-form-row >
                        <b-col md="6">
                          <TextInput id="nom" type="text" label="Nom" :rules="{ required: true, min: 3 }" v-model="informations.liv_contact"></TextInput>
                        </b-col>
                        <b-col md="6">
                          <TextInput id="prenom" type="text" label="Prénom" :rules="{ required: true, min: 3 }" v-model="informations.liv_contact_prenom"></TextInput>
                        </b-col>
                      </b-form-row>
                      <b-form-row >
                        <b-col md="12">
                          <TextInput id="adresse" type="text" label="Adresse" :rules="{ required: true, min: 3 }" v-model="informations.liv_adresse"></TextInput>             
                        </b-col>
                      </b-form-row>
                      <b-form-row>            
                        <b-col md="3">
                          <TextInput id="codePostal" type="number" label="Code postal" :rules="{ required: true, min: 5 , max:5}" v-model="informations.liv_code_postal"></TextInput>             
                        </b-col>
                        <b-col md="9">
                          <TextInput id="ville" type="text" label="Ville" :rules="{ required: true, min: 3 }" v-model="informations.liv_ville"></TextInput>             
                        </b-col>
                      </b-form-row>
                      <b-form-row>
                        <b-col md="6">
                          <TextInput id="email" type="email" label="Email" :rules="{ required: true, email: true}" v-model="informations.liv_email"></TextInput>             
                        </b-col>
                        <b-col md="3">
                          <TextInput id="telephone" type="tel" label="Tél." :rules="{ required: false, min: 10, max:10 }" v-model="informations.liv_telephone"></TextInput>             
                        </b-col>
                        <b-col md="3">
                          <TextInput id="mobile" type="tel" label="Mobile" :rules="{ required: true, min: 10 , max: 10}" v-model="informations.liv_mobile"></TextInput>                 
                        </b-col>
                      </b-form-row>
                      <b-form-row>
                        <b-col md="12">
                        </b-col>
                      </b-form-row>
                </b-card>
              </b-col>             
            </b-form-row>
            <b-form-row>
              <b-col md="12">
                <b-card title="Prise de rendez-vous" class="mb-3">
                  <b-form-row>
            <b-col md="6">
               <b-form-group
                    label="Date du rendez-vous"
                    label-for="dateRDV"
                    invalid-feedback="La date de rendez-vous est requise"
                    :state="dateState"
                  >
                  <b-form-datepicker id="dateRDV" placeholder="Choisir une date" locale="fr" v-model="dateRDV" required :state="dateState"></b-form-datepicker>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                    label="Heure"
                    label-for="heureRDV"
                    invalid-feedback="La date de rendez-vous est requise"
                    :state="heureState"
                  >
              <b-form-timepicker id="heureRDV" placeholder="Choisir une heure" v-model="heureRDV" minutes-step="15" locale="fr"></b-form-timepicker>
              </b-form-group>
            </b-col>
          </b-form-row>
          <b-form-row >
            <b-col md="12">
              <TextInput id="notes" type="textarea" label="Notes" :rules="{ required: false, min:3}" v-model="notes"></TextInput>             
            </b-col>
          </b-form-row>
                </b-card>
              </b-col>
            </b-form-row>
          </b-form>
      </validation-observer>     
    </b-modal>
  </b-container>
</template>

<script>
import EvenementService from '../services/EvenementService'
import ClientService from '../services/ClientService'
import TextInput from '../components/TextInput.vue'
const _ = require('lodash');

export default {
  name: 'MClients',
  components: {
    TextInput
  },
  data: () => ({
    message : '',
    typeClient : "Particulier",
    typeClientList : ["Particulier","Entreprise"],
    memeAdresse : true,
    loading : false,
    clients: [],
    filtreClients : [],
    keyword :'',
    dateState : '',
    dateRDV: '',
    dateNaissanceState: '',
    heureRDV: '12:00',
    heureState: '',
    notes:'',
    mpr:'',
    searchState: null,
    articleState : null,
    hoursOfDay: [
        '9:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00'
      ],
    
  }),
  mounted(){
    this.loadClients()
  },
  computed : {
    informations() {
      return this.$store.getters.client
    },

    id_organisation() {
      return this.$store.getters.idOrganisation
    },
    id_user_membre() {
      return this.$store.getters.id_user_membre
    },
    todayAppointments() {
      return this.filtreClients.filter(x=> {
        const date = new Date(x.date_calendar_debut_tri)
        const now = new Date()
        return date.toDateString() == now.toDateString()
      })
    }
    ,
    pastAppointments() {
      const past = this.filtreClients.filter(x=> {
        const date = new Date(x.date_calendar_debut_tri)
        const now = new Date()
        return date < now
      })

      return _.chain(past)
            .groupBy("date_calendar_debut")
            .toPairs()
            .map(function(currentData){
              return _.zipObject(["date_calendar_debut", "appointments"], currentData);
            })
            .orderBy(['date_calendar_debut'])
            .value();
      },
    futurAppointments() {
      const futur = this.filtreClients.filter(x=> {
        const date = new Date(x.date_calendar_debut_tri)
        const now = new Date()
        return date > now
      })

      return _.chain(futur)
            .groupBy("date_calendar_debut")
            .toPairs()
            .map(function(currentData){
              return _.zipObject(["date_calendar_debut", "appointments"], currentData);
            })
            .orderBy(['date_calendar_debut'])
            .value();
      }
  },
  watch: {
    informations: {
      handler(){
        if(this.informations.codePostal != null && this.totalPersonnes !=null && this.totalRevenu !=null) {
          this.showTabs = true
        }
        this.$refs.observer.validate().then(success => {
          //this.showTabs = success

          if(success) {
            this.$store.dispatch("setClient", this.informations)
          }
        })
      },
      deep: true
    },
  },

  methods: {
    onSubmit (evt) {
      evt.preventDefault()
      alert(JSON.stringify(this.form))
    },
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    newCustomer(numTransaction) {
      return {
        code_app : "APP_COMMERCIALE",
        numTransaction : numTransaction,
        id_user_membre : this.id_user_membre,
        typeop : 3,
        statutOrga : 1,
        id_origine_affaire_ecommerce : 500,
        id_secteur_organisation : 0,
        nom : this.informations.nom,
        prenom : this.informations.prenom,
        raison_sociale : this.informations.raison_sociale,
        siren : this.informations.siren,
        adresse : this.informations.adresse,
        codePostal : this.informations.codePostal,
        ville : this.informations.ville,
        id_pays : 1,
        mobile: this.informations.mobile,
        telephone: this.informations.telephone,
        email : this.informations.email,
        note_libre : this.notes,
        fiche_technique : ""
      }
    },
    loadClients(){
        this.loading= true;
        EvenementService.Events(this.id_organisation, this.id_user_membre, 0,20).then((result) => {
          this.loading = false;
          if(result.data.statut == 'OK') {
            this.totalRows = result.data.list.length
            this.clients = result.data.list.map(x => {
              return {
                id: `${x.id_suivi}_${x.da_id_dossier != null ? x.da_id_dossier : 0 }`,
                id_suivi : x.id_suivi,
                id_organisation: x.id_organisation,
                raison_sociale: x.raison_sociale,
                //code_postal: x.code_postal,
                libelle_type_evt : x.libelle_type_evt,
                date_calendar_debut : x.date_calendar_debut,
                date_calendar_debut_tri : x.date_calendar_debut_tri,
                adresse_rdv : x.adresse_rdv,
                num_tel_1_rdv : x.num_tel_1_rdv,
                num_tel_2_rdv : x.num_tel_2_rdv,
                email_rdv : x.email_rdv,
                da_code_dossier : x.da_code_dossier,
                da_id_dossier : x.da_id_dossier,
                facturation_code_facturation : x.facturation_code_facturation,
                facturation_id_facturation : x.facturation_id_facturation,
                facturation_id_piece_facturation : x.facturation_id_piece_facturation

                //zone: x.zone,
                //email: x.email
            }})
            this.filtreClients = JSON.parse(JSON.stringify(this.clients))
          }
          //console.log(this.clients)
        }).catch(err => {
            this.$bvModal.msgBoxOk(`Impossible de charger la liste des clients : ${err.message}`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
        });
    },
    searchClient(){
      if(this.keyword.length < 3) {
        this.filtreClients = JSON.parse(JSON.stringify(this.clients))
        return
      }

      this.filtreClients = this.clients.filter(
        x=> x.raison_sociale.toUpperCase().includes(this.keyword.toUpperCase())
        )
    },
    checkFormClientValidity() {
        let valid = this.$refs.formRDV.checkValidity()

        if(!this.dateRDV)
          this.dateRDV = valid
        else
          this.dateRDV = true

      
        return valid
    },
    resetModalClient() {
      this.dateState = null
    },
    openModalClient() {
      this.$bvModal.show(`modal-client`)
    },
    handleClientOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      this.$refs.observer.validate().then(success => {
          if(success) {
            // Trigger submit handler
            this.handleSubmitClient()
          }
        })

    },
    async handleSubmitClient() {
      // Exit when the form isn't valid



        var numTransaction = Math.floor(Math.random() * 100000) + 1;
        var customer = this.newCustomer(numTransaction)
        let idFiche = 0
        let result = null
        try {
          result = await ClientService.CreateCustomer(customer)
          // Creation du dossier
          if(result.status =="NOK") {
            console.log(result.message_specifique)
            if(result.error_number == 1062) {
              // le client existe deja
              
              // message : 
              this.$bvModal.msgBoxOk(`Erreur : Le client existe déjà`, {
                title: 'Erreur',
                okVariant: 'danger',
                headerClass: 'p-2 border-bottom-0',
                footerClass: 'p-2 border-top-0',
                centered: true
              })
              return       
            }
            else {
              this.message = result.message_specifique
              return
            }
          }

          idFiche = result.data.id_fiche 

            var event = {
              code_app : "APP_COMMERCIALE",
              numTransaction : numTransaction,
              id_user_membre : this.id_user_membre,
              typeop : 1,
              code_type_evt : "EVT_VAD",
              e_actif : 1,
              date_calendar_debut : this.dateRDV,
              heure_date_calendar_debut : this.heureRDV,
              date_calendar_fin : "",
              heure_date_calendar_fin : "",
              id_user_membre_for: this.id_user_membre,
              id_criticite : 0,
              id_statut_rdv : 0,
              commentaire : ""
          }

        console.log(event)
        EvenementService.CreateEvent(idFiche, event).then(result => {
          if(result.data.statut =='OK') {
            this.$bvModal.msgBoxOk('RDV enregistré avec succès', {
              title: 'Confirmation',
              okVariant: 'success',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            this.loadClients()
          }
            else {
            //error
            this.$bvModal.msgBoxOk(`Impossible d'enregistrer le RDV : ${result.data.message_specifique}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
            }
          }).catch(err => {
          this.$bvModal.msgBoxOk(`Impossible d'enregistrer le RDV : ${err.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
          })


        } catch (error) {
          this.$bvModal.msgBoxOk(`Impossible d'enregistrer le RDV : ${error.message}`, {
              title: 'Erreur',
              okVariant: 'danger',
              headerClass: 'p-2 border-bottom-0',
              footerClass: 'p-2 border-top-0',
              centered: true
            })
        }
      
          this.informations.nom = ''
          this.informations.nom_conjoint = ''
          this.informations.prenom = ''
          this.informations.prenom_conjoint = ''
          this.informations.adresse = ''
          this.informations.codePostal = null
          this.informations.ville = ''
          this.informations.mobile = null
          this.informations.telephone = null
          this.informations.email = ''
          this.dateRDV =''
          this.heureRDV = ''
          this.notes = ''
          this.mpr = ''
        // Hide the modal manually
        this.$nextTick(() => {
          this.$bvModal.hide('modal-client')
        })
    },
    onReset (evt) {
      evt.preventDefault()
      // Trick to reset/clear native browser form validation state
      this.show = false
      this.$nextTick(() => {
        this.show = true
      })
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1, h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
legend {
  font-size: 16px;
}

</style>
