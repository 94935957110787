<template>
  <div>
      <!-- <b-form-row>
        <b-col md="6">
          <Selection></Selection>
        </b-col>
      </b-form-row> -->
      <b-form-row>
        <b-col md="12">
          <ul>
            <li v-for='error in errors' :key="error.id">{{error.value}}</li>
          </ul>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col md="12">
          <InstallationPacks :viewOnly='viewOnly' :famille="famille" @calculTotalTTC="updateTotalTTC"/>
        </b-col>
      </b-form-row>
      <b-form-row >
        <b-col md="12" >
           <Aides :informations="informations"></Aides>
        </b-col>
      </b-form-row>
      <b-form-row v-if='selectedPacks.length > 0'>
        <b-col md="12">
          <div class="mt-3">
            <b-card-group deck >
              <b-card border-variant="info" header="TOTAL NET A REGLER TTC" header-bg-variant="info" header-text-variant="white"  align="center">
                <b-card-text>
                  {{totalNetTTC | separators}}
                </b-card-text>
              </b-card>
            </b-card-group>
          </div>
        </b-col>
      </b-form-row>
      <b-form-row v-if='selectedPacks.length > 0'>
        <b-col md="12">
          <div class="mt-3">
            <b-card-group deck >
              <b-card border-variant="info" header="RESTE A CHARGE" header-bg-variant="info" header-text-variant="white"  align="center">
                    Le reste à charge est de {{resteACharge | separators }} soit {{ratio | percent }}
              </b-card>
              <b-card border-variant="info" header="RAC Requis (Sur Fonds Publiques)" header-bg-variant="info" header-text-variant="white"  align="center">
                  <b-card-text>
                     <span :class="resteACharge < resteAChargeHCV ? 'text-danger' : '' ">{{resteAChargeMessage}} soit {{resteAChargeHCV | separators }}</span>
                  </b-card-text>
                </b-card>
            </b-card-group>

          </div>
        </b-col>
      </b-form-row>
  </div>
</template>

<script>
import InstallationPacks from './InstallationPacks'
import Aides from './Aides'
//import Selection from './Selection'
//import ClientService from '../services/ClientService'
//import { mapGetters } from "vuex";
//import CatalogService from '../services/CatalogService'

export default {
name: 'Installation',
props : ['informations', 'typeDossier','famille'],
  components: {
    InstallationPacks,
    Aides,
    //Selection
    //TextInput
  },
  data: () => ({
    loading: false,
    packId : null,
    packState : null,
    afficheSelectionProduit : false,
    filtreFabricants : [],
    viewOnly : false,
    errors : [],
    fabricantId: 0,
    packListFabricant : [],
    resteAChargeHCV:0,
    resteACharge:0,
    ratioHCV:0,
    ratio:0,
    resteAChargeMessage : "",
    totalTTC:0
  }),
  computed: {
    idOrganisation() {
      return this.$store.getters.idOrganisation
    },
    packList(){
      return this.$store.getters.packList
    },
    packs () {
      return this.$store.getters.packs
    },
    fabricants () {
      return this.$store.getters.fabricants
    },
    fabricantsDefaut () {
      return this.$store.getters.fabricantsDefaut
    },
    selectedPacks() {
      return this.$store.getters.selectedPacks
    },
    selectedAides() {
      return this.$store.getters.selectedAides
    },
    modelVT() {
      return this.$store.getters.modelVT
    },
    delta () {
      return this.$store.getters.delta
    },
    totalNetTTC () {
      return this.totalTTC - this.$store.getters.totalDeduit;
    },
    totalAides() {
      return this.$store.getters.totalAides
    },
    oblige() {
      return this.$store.getters.oblige
    },
    client() {
      return this.$store.getters.client
    }
  },
  watch : {
    modelVT: {
      handler(){
        
        if(this.modelVT.produits.includes('RO') || this.modelVT.produits.includes('RR')) {

            //Année de la maisoon
            if(this.modelVT.blocs[0].questions[0].value == null) {
              if(!this.errors.find(x=> { return x.id == 999})) {
                this.errors.push({id: 999, value:"Merci de renseigner l'année de la maison (Ma Maison)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 999})
            }

            if(this.modelVT.blocs[0].questions[0].value == 'Entre 2 et 15 ans' && !this.modelVT.blocs[2].questions[1].sub_questions[3].value) {
              if(!this.errors.find(x=> { return x.id == 1000})) {
                  this.errors.push({id: 1000, value:"Merci de renseigner si il y'a dépose de la cuve (Mon chauffage)"})
                }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 1000})
            }


          //if(this.modelVT.superficie > 0 || this.modelVT.phase) {
            // PAC
            if(this.modelVT.superficie <= 0) {
              if(!this.errors.find(x=> { return x.id == 1})) {
                this.errors.push({id: 1 , value:"Merci de renseigner une superficie (Ma Maison)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 1})
            }
            
            //PAC
            if(!this.modelVT.phase) {
              if(!this.errors.find(x=> { return x.id == 2})) {
                this.errors.push({id: 2 , value:"Merci de renseigner le type d'installation électrique (Mon raccordement electrique)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 2})
            }
          //}

          if(this.modelVT.plancherChauffant=='Oui' && this.modelVT.radiateurs == 0) {
              if(!this.errors.find(x=> { return x.id == 99})) {
                this.errors.push({id: 99 , value:"Merci d'indiquer le nombre de radiateurs (Mon chauffage)"})
              }
          }
          else {
            this.errors = this.errors.filter(x => { return x.id != 99})
          }
        }
        else {
          this.errors = this.errors.filter(x => { return ![99, 2, 1, 1000, 999].includes(x.id)})
        }

        if(this.modelVT.produits.includes('EC')) {
          if(this.modelVT.productionECS || this.modelVT.nbOccupants || this.modelVT.hauteurSousPlafond || this.modelVT.chauffagePrincipal) {  
            //BTD
            if(!this.modelVT.productionECS) {
              if(!this.errors.find(x=> { return x.id == 0})) {
                this.errors.push({id: 0 , value:"Merci de renseigner la production d'eau chaude sanitaire (Mon ECS)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 0})
            }
  
            //BTD
            if(!this.modelVT.nbOccupants) {
              if(!this.errors.find(x=> { return x.id == 3})) {
              this.errors.push({id: 3 , value:"Merci de renseigner le nombre d'occupants adultes et enfants (Mon ECS)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 3})
            }        
  
            //BTD
            if(!this.modelVT.hauteurSousPlafond) {
              if(!this.errors.find(x=> { return x.id == 4})) {
                this.errors.push({id: 4 , value:"Merci de renseigner la hauteur sous plafond (Mon ECS)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 4})
            }
  
            //BTD
            if(!this.modelVT.chauffagePrincipal) {
              if(!this.errors.find(x=> { return x.id == 5})) {
                this.errors.push({id: 5 , value:"Merci de renseigner le type de chauffage principal (Mon chauffage)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 5})
            }
  
            //BTD
            if(this.modelVT.chauffagePrincipal == "Chauffage central" && !this.modelVT.chaudiereCondensation) {
              if(!this.errors.find(x=> { return x.id == 6})) {
                this.errors.push({id: 6 , value:"Merci d'indiquer s'il s'agit d'une chaudière à condensation (Mon chauffage)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 6})
            }
          }
        }
        else {
          this.errors = this.errors.filter(x => { return ![0, 3, 4 ,5 ,6].includes(x.id)})
        }


        if(this.modelVT.produits.includes('PV')) {
          if(this.modelVT.qtePanneaux <= 0) {
              if(!this.errors.find(x=> { return x.id == 7})) {
                this.errors.push({id: 7 , value:"Merci de renseigner la quantité de panneaux photovoltaïque à installer (Ma toiture)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 7})
            }
        }
        else {
          this.errors = this.errors.filter(x => { return x.id != 7})
        }

        if(this.modelVT.produits.includes('VMC')) {
          if(!this.modelVT.combles) {
              if(!this.errors.find(x=> { return x.id == 8})) {
                this.errors.push({id: 8 , value:"Merci de renseigner la présence de combles aménagées (Ma maison)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 8})
            }

            if(!this.modelVT.amenagement) {
              if(!this.errors.find(x=> { return x.id == 9})) {
                this.errors.push({id: 9 , value:"Merci de renseigner si la maison est de plein pied ou à étage (Ma maison)"})
              }
            }
            else {
              this.errors = this.errors.filter(x => { return x.id != 9})
            }
        }
        else {
          this.errors = this.errors.filter(x => { return x.id != 8 && x.id != 9})
        }
        
        if(this.errors.length <= 1) {
          this.filtrerPack()
        }
      },
      deep: true
    },
    fabricantsDefaut: {
      handler(){
        this.filtrerPack()
      },
      deep: true
    },
    totalNetTTC: {
      handler() {
        this.calculResteACharge()
      }
    }
  },
  methods: {
    updateTotalTTC(totalTTC) {
      this.totalTTC = totalTTC
    },
    refreshCatalog() {
        this.loading = true
        this.$store.dispatch('getPacks', this.idOrganisation).then(() => {
          this.filtreFabricants = [...this.fabricants]
          this.fabricantId = this.filtreFabricants[0].value
          this.selectFabricant()
          this.loading = false
        })
    },
    ajouterPack(packId){
      this.packState = null
      const pack = this.packs.filter(p => p.id == packId)
      if(pack.length >0) {
        this.$store.dispatch("addPack", pack[0]) 
        this.packId = null
      }
      else {
        this.packState = false
      }
    },
    filtrerPack() {
      //this.afficheSelectionProduit = false
      console.log(this.modelVT.phase)
      console.log(this.modelVT.superficie)
      console.log(this.modelVT.nbOccupants)
      console.log(this.modelVT.hauteurSousPlafond)
      console.log(this.modelVT.produits)
      console.log(this.modelVT.materiel)
      console.log(this.modelVT.produits)

      this.$store.dispatch('deleteAllPack')
      let fabricantId = null

      let pack = null
      fabricantId = this.fabricantsDefaut.find(x=> x.label == "Pompe à Chaleur Air/Eau").value

    if(this.modelVT.produits.includes('RO') || this.modelVT.produits.includes('EC')) {
      if(this.modelVT.materiel == "ECS Intégré") {
        pack = this.packs.filter(
          p => p.superficieMin <= this.modelVT.superficie 
          && p.superficieMax >= this.modelVT.superficie 
          && this.modelVT.superficie > 0
          && p.gamme == this.modelVT.phase
          && p.duo == true
          && p.fabricantId == fabricantId)

          if(pack.length > 0) {
            this.ajouterPack(pack[0].id) 
            this.viewOnly = false
          }

      }else {
        pack = this.packs.filter(
         p => p.superficieMin <= this.modelVT.superficie 
         && p.superficieMax >= this.modelVT.superficie 
         && this.modelVT.superficie > 0
         && p.gamme == this.modelVT.phase
         && p.duo == false
         && p.fabricantId == fabricantId)
 
       if(pack.length > 0) {
         this.ajouterPack(pack[0].id) 
         this.viewOnly = false
       }


       //Kit Bizone - type PAC
       if(this.modelVT.kitBizone) {
        pack = this.packs.filter(
         p => p.bizone == true
         && p.fabricantId == fabricantId)
 
        if(pack.length > 0) {
          this.ajouterPack(pack[0].id) 
          this.viewOnly = false
        }
       }
 
      fabricantId = this.fabricantsDefaut.find(x=> x.label == "Ballon Thermodynamique").value
       if(this.modelVT.materiel == 'BTD') {
         if(this.modelVT.hauteurSousPlafond > 0 && this.modelVT.hauteurSousPlafond <= 210) 
         {
           pack = this.packs.filter(
           p => p.personneMin <= this.modelVT.nbOccupants 
           && p.personneMax >= this.modelVT.nbOccupants 
           && this.modelVT.nbOccupants > 0
           && p.type == 'Ballon Thermodynamique'
           && p.split == true
           && p.fabricantId == fabricantId)
         }
         else {
           pack = this.packs.filter(
           p => p.personneMin <= this.modelVT.nbOccupants 
           && p.personneMax >= this.modelVT.nbOccupants 
           && this.modelVT.nbOccupants > 0
           && p.type == 'Ballon Thermodynamique'
           && p.split == false
           && p.fabricantId == fabricantId)
         }

        if(pack.length > 0) {
            this.ajouterPack(pack[0].id) 
            this.viewOnly = false
        }
       }

      fabricantId = this.fabricantsDefaut.find(x=> x.label == "Ballon Electrique").value
       if(this.modelVT.materiel == 'Ballon Electrique') {
         pack = this.packs.filter(
         p => p.personneMin <= this.modelVT.nbOccupants 
         && p.personneMax >= this.modelVT.nbOccupants 
         && p.type == 'Ballon Electrique'
         && p.fabricantId == fabricantId)
 
         if(pack.length > 0) {
           this.ajouterPack(pack[0].id) 
           this.viewOnly = false
         }
       }

      fabricantId = this.fabricantsDefaut.find(x=> x.label == "Ballon Solaire").value
       if(this.modelVT.materiel == 'Ballon Solaire') {
         pack = this.packs.filter(
         p => p.personneMin <= this.modelVT.nbOccupants 
         && p.personneMax >= this.modelVT.nbOccupants 
         && this.modelVT.nbOccupants > 0
         && p.type == 'Ballon Solaire'
         && p.fabricantId == fabricantId)
 
         if(pack.length > 0) {
           this.ajouterPack(pack[0].id) 
           this.viewOnly = false
         }
       }
      }
    }

    if(this.modelVT.produits.includes('PV')){
       //Panneaux photovoltaique
       fabricantId = this.fabricantsDefaut.find(x=> x.label == "Kits résidentiels 3, 6, 9KW").value
         pack = this.packs.filter(
          p => p.qtePanneaux == this.modelVT.qtePanneaux 
          && p.puissancePanneaux == this.modelVT.puissancePanneaux 
          && this.modelVT.qtePanneaux > 0
          && p.fabricantId == fabricantId
        )
 
         if(pack.length > 0) {
           this.ajouterPack(pack[0].id) 
           this.viewOnly = false
         }
    }

        //VMC
    if(this.modelVT.produits.includes('VMC')){
      fabricantId = this.fabricantsDefaut.find(x=> x.label == "Double Flux").value
      if(this.modelVT.VMC) {
         pack = this.packs.filter(
         p => p.fabricantId == fabricantId
          && p.type == 'Double Flux'
        )
 
         if(pack.length > 0) {
           this.ajouterPack(pack[0].id) 
           this.viewOnly = false
         }
       }
     }
    },
    selectFabricant() {
      this.packListFabricant = JSON.parse(JSON.stringify(this.packList))
      this.packListFabricant.forEach(group => {
        group.options = group.options.filter(item => item.value.fabricantId == this.fabricantId)
        if(group.options.length > 0) {
          group.options.unshift({value:null, text:"Choisir ..."})

        }
      })


      
      //this.packId = this.packListFabricant[0].options[0].id

    },
    calculResteACharge() {
      console.log("Calcul RAC")
      let chequeVert = this.selectedAides[4].prime
      
      this.resteACharge = this.totalNetTTC
      this.ratio = this.resteACharge / this.totalTTC

      this.resteAChargeHCV = this.resteACharge
      
      this.ratioHCV = this.resteAChargeHCV / this.totalTTC
     this.resteAChargeMessage = ""
      switch (this.client.id_categorieMPR) {
        case "1":
          this.resteAChargeHCV = ((this.totalAides + chequeVert) * 0.1) / 0.9
          //this.resteAChargeHCV = this.totalTTC * 0.1
            //if(this.ratio < 0.1)
              this.resteAChargeMessage = `10 % pour les Grands Précaires`
          break;
        case "2":
            //if(this.ratio < 0.25)
            this.resteAChargeHCV = ((this.totalAides + chequeVert) * 0.25) / 0.75
            //this.resteAChargeHCV = this.totalTTC * 0.25
            this.resteAChargeMessage = `25 % pour les Précaires`
          break;
        case "3":
            //if(this.ratio < 0.40)
            this.resteAChargeHCV = ((this.totalAides + chequeVert) * 0.6) / 0.4
            //this.resteAChargeHCV = this.totalTTC *  0.4
            this.resteAChargeMessage = `40 % pour les Intermédiaires`
          break;
        case "4":
            //if(this.ratio < 0.60)
            this.resteAChargeHCV = ((this.totalAides + chequeVert) * 0.4) / 0.6
            //this.resteAChargeHCV = this.totalTTC *  0.6
            this.resteAChargeMessage = `60 % pour les Plus Elevés`
          break;
        default:
          break;
      }
    }
  }
}
</script>

<style>

</style>